











import Vue from 'vue'
import Component from 'vue-class-component'

@Component({ components: {}, props: [] })
export default class LeftRightAdjust extends Vue {
  private onClick (delta: -1 | 1) {
    this.$emit('change', delta)
  }
}
