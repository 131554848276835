




























































































































































/* eslint-disable no-undef */
import { Component, Prop, Vue, Mixins } from 'vue-property-decorator'
import { google } from '@google-cloud/vision/build/protos/protos'
import { fabric } from 'fabric'
import _ from 'lodash'
import { RotatedRect } from '@/utils/types'
import { pixelOnLine, imageFromUrl } from '@/utils/drawing'
import { Api } from '@/edshed-common/api'
import LeftRightAdjust from '@/components/common/LeftRightAdjust.vue'
import ModalMixin from '@/edshed-common/mixins/ModalMixin'
import store from '@/store'
import { LoadedWritingPiece, LoadedWritingPieceWord, newWritingPiece, regenerateWordImage, saveWritingPiece, loadWritingPiece, reindexWritingPiece, computeText, removeWords } from './writing-piece-helper'

Component.registerHooks([
  'beforeRouteEnter'
])
@Component({
  name: 'WritingPieceImport',
  components: { LeftRightAdjust }
})
export default class WritingPieceImportView extends Mixins(ModalMixin) {
  private dropFile: File | null = null
  private canvasContainer!: HTMLDivElement
  private canvasElement!: HTMLCanvasElement
  private canvas!: fabric.Canvas
  private image!: HTMLImageElement
  private scaleRatio = 1
  private writingPiece: LoadedWritingPiece | null = null
  private wordEditIndices: number[] = []
  private status: 'none' | 'saving' | 'loading' = 'none'
  private isZoomed = false

  private readonly resizeHandler = _.debounce(() => {
    this.resizeCanvas()
    this.$forceUpdate()
  }, 1000)

  // Redirect to new home for non-superusers
  beforeRouteEnter (to, from, next) {
    if (store.state.user && store.state.user.superuser) {
      next()
    } else {
      let locale = 'en-gb'
      if (store.state.user && store.state.user.locale && store.state.user.locale) {
        locale = store.state.user.locale.toLowerCase().replace('_', '-')
      }
      window.location.replace(`https://www.edshed.com/${locale}/pupils/${to.params.pupil_id}/writing_pieces/import/new`)
    }
  }

  public mounted () {
    window.addEventListener('resize', this.resizeHandler)
    $('body').on('collapsed.pushMenu', this.resizeHandler)
    $('body').on('expanded.pushMenu', this.resizeHandler)

    this.$watch('dropFile', (file: File) => this.onFile(file))

    this.$nextTick(() => {
      this.canvasContainer = this.$refs.canvasContainer as HTMLDivElement
      this.canvasElement = this.$refs.canvas as HTMLCanvasElement

      // (window as any).$('body').addClass('sidebar-collapse')

      const { piece_id } = this.$route.params

      if (piece_id !== 'new') {
        this.load(parseInt(piece_id, 10))
      }
    })
  }

  public destroy () {
    window.removeEventListener('resize', this.resizeHandler)
    $('body').off('collapsed.pushMenu', this.resizeHandler)
    $('body').off('expanded.pushMenu', this.resizeHandler)
  }

  private onZoom () {
    this.isZoomed = !this.isZoomed

    this.$nextTick(() => {
      this.resizeCanvas()
    })
  }

  private onDeleteWords () {
    if (!this.writingPiece) { return }

    const removedWords = removeWords(this.writingPiece, this.wordEditIndices)

    this.canvas.remove(...removedWords.map(w => w.__rect!))
    this.canvas.discardActiveObject()

    this.wordEditIndices = []

    this.canvas.requestRenderAll()
    this.computeText()
  }

  private onActiveWordChange (index: number) {
    if (!this.writingPiece) { return }

    this.wordEditIndices = [index]

    const word = this.writingPiece.words[index]

    this.canvas.setActiveObject(word.__rect!)

    this.canvas.requestRenderAll()
    this.$forceUpdate()
  }

  private onCanvasSelectionChange (indexes: number[]) {
    if (!this.writingPiece) { return }

    this.wordEditIndices = indexes

    this.$forceUpdate()
  }

  private onWordTextChange (index: number, newText: string) {
    if (!this.writingPiece) { return }

    const word = this.writingPiece!.words[index]

    word.written_text = word.correct_text = newText

    if (newText.includes(' ')) {
      return this.onSplitWord(index)
    }

    this.computeText()
  }

  private onWordCorrectTextChange (index: number, newText: string) {
    if (!this.writingPiece) { return }

    const word = this.writingPiece!.words[index]

    word.correct_text = newText

    this.computeText()
  }

  private onWordOverlayChanged (words: LoadedWritingPieceWord[]) {
    if (!this.writingPiece) { return }

    for (const word of words) {
      if (!word.__rect) { continue }

      const group = word.__rect.group

      word.rect.x = word.__rect.left! + (group ? group.width! / 2 + group.left! : 0)
      word.rect.y = word.__rect.top! + (group ? group.height! / 2 + group.top! : 0)
      word.rect.width = word.__rect.getScaledWidth()!
      word.rect.height = word.__rect.getScaledHeight()!
      word.rect.angle = word.__rect.angle! * Math.PI / 180

      regenerateWordImage(this.writingPiece, word)
    }

    this.$forceUpdate()
  }

  private onAdjustWord (index: number, side: 'leftside' | 'rightside', delta: -1 | 1) {
    this.onActiveWordChange(index)

    if (!this.writingPiece) { return }

    const word = this.writingPiece.words[index]

    if (side === 'leftside') {
      word.rect.x = word.__rect!.left = word.rect.x + delta
      word.rect.width = word.__rect!.width = word.rect.width - delta * 2
    }

    if (side === 'rightside') {
      word.rect.x = word.__rect!.left = word.rect.x + delta
      word.rect.width = word.__rect!.width = word.rect.width + delta * 2
    }

    regenerateWordImage(this.writingPiece, word)
  }

  private onSplitWord (index: number) {
    if (!this.writingPiece) { return }

    const word = this.writingPiece.words[index]

    const removedWords = removeWords(this.writingPiece, [index])

    this.canvas.remove(...removedWords.map(w => w.__rect!))
    this.canvas.discardActiveObject()

    const [text1, text2] = word.written_text.split(' ')

    const newWord1: LoadedWritingPieceWord = {
      idx: -1,
      written_text: text1,
      correct_text: text1,
      rect: {
        x: word.rect.x - word.rect.width / 4,
        y: word.rect.y,
        width: word.rect.width / 2,
        height: word.rect.height,
        angle: word.rect.angle
      },
      __rect: null,
      __image: null,
      __dirty: true
    }

    const newWord2: LoadedWritingPieceWord = {
      idx: -1,
      written_text: text2,
      correct_text: text2,
      rect: {
        x: word.rect.x + word.rect.width / 4,
        y: word.rect.y,
        width: word.rect.width / 2,
        height: word.rect.height,
        angle: word.rect.angle
      },
      __rect: null,
      __image: null,
      __dirty: true
    }

    this.writingPiece.words = [
      ...this.writingPiece.words.slice(0, index),
      newWord1,
      newWord2,
      ...this.writingPiece.words.slice(index)
    ]

    reindexWritingPiece(this.writingPiece)

    regenerateWordImage(this.writingPiece, newWord1)
    regenerateWordImage(this.writingPiece, newWord2)

    this.initCanvas()
    this.computeText()
  }

  private async onFile (file: File) {
    try {
      if (this.status !== 'none') { return }

      this.status = 'loading'

      await this.processNewImage(file)
    } catch (err: unknown) {
      if (err instanceof Error) {
        this.alert({ title: 'Could not parse', message: err.message })
      }
    } finally {
      this.status = 'none'
    }
  }

  private async onSave () {
    if (!this.writingPiece || this.status !== 'none') { return }

    try {
      this.status = 'saving'

      this.writingPiece = await saveWritingPiece(this.writingPiece)

      const { piece_id } = this.$route.params

      if (piece_id === 'new') {
        return this.$router.push(`/pupils/${this.writingPiece.owner_id}/writing_pieces/import/${this.writingPiece.id}`)
      }

      this.computeText()
      await this.initCanvas()
      this.$forceUpdate()
    } catch (err: unknown) {
      if (err instanceof Error) {
        this.alert({ title: 'Could not save', message: err.message })
      }
    }

    this.status = 'none'
  }

  private async onDelete () {
    if (!this.writingPiece || !this.writingPiece.id || this.status !== 'none') { return }

    try {
      this.status = 'saving'

      await Api.deleteWritingPiece(this.writingPiece.id)

      return this.$router.push(`/pupils/${this.writingPiece.owner_id}`)
    } catch (err: unknown) {
      if (err instanceof Error) {
        this.alert({ title: 'Could not delete', message: err.message })
      }
    }

    this.status = 'none'
  }

  private isWordEditing (index: number) {
    return this.wordEditIndices.includes(index)
  }

  private getWordStyle (word: LoadedWritingPieceWord): Partial<CSSStyleDeclaration> {
    const { width, height } = word.rect
    let { x, y } = word.rect

    x -= width / 2
    y -= height / 2

    x *= this.scaleRatio
    y *= this.scaleRatio

    const style: Partial<CSSStyleDeclaration> = {
      left: `${x}px`,
      top: `${y}px`,
      width: `${width * this.scaleRatio}px`,
      height: `${height * this.scaleRatio}px`
    }

    return style
  }

  private computeText () {
    if (!this.writingPiece) { return }

    computeText(this.writingPiece)
  }

  private async load (piece_id: number) {
    this.writingPiece = await loadWritingPiece(piece_id)

    this.computeText()
    await this.initCanvas()
    this.$forceUpdate()
  }

  private async processNewImage (blob: Blob) {
    const { pupil_id } = this.$route.params

    this.writingPiece = await newWritingPiece(parseInt(pupil_id, 10), blob)

    this.computeText()
    await this.initCanvas()
    this.correctBadBoundaries()
    this.$forceUpdate()
  }

  private getWordsForRects (rects: fabric.Object[]) {
    if (!this.writingPiece) { return [] }

    const words: LoadedWritingPieceWord[] = []

    for (const word of this.writingPiece!.words) {
      if (rects.includes(word.__rect!)) {
        words.push(word)
      }
    }

    return words
  }

  private async initCanvas () {
    if (!this.writingPiece) { return }

    if (this.canvas) {
      this.canvas.dispose()
    }

    this.image = await imageFromUrl(this.writingPiece.__image)

    this.canvasElement.width = this.image.width
    this.canvasElement.height = this.image.height

    this.canvas = new fabric.Canvas(this.canvasElement)

    const selectionHandler = (e: fabric.IEvent) => {
      const selection = e.target

      if (selection instanceof fabric.Rect) {
        const words = this.getWordsForRects([selection])
        const indices = words.map(({ idx }) => idx)

        this.onCanvasSelectionChange(indices)
      } else if (selection instanceof fabric.ActiveSelection) {
        const words = this.getWordsForRects(selection.getObjects())
        const indices = words.map(({ idx }) => idx)

        this.onCanvasSelectionChange(indices)

        selection.on('moved', (e) => {
          this.onWordOverlayChanged(words)
        })
      }
    }

    this.canvas.on('selection:updated', selectionHandler)
    this.canvas.on('selection:created', selectionHandler)

    this.resizeCanvas()

    const imageShape = new fabric.Image(this.image, { left: 0, top: 0 })

    this.canvas.setBackgroundImage(imageShape, () => undefined)
    this.canvas.renderAll()

    for (const word of this.writingPiece.words) {
      word.__rect = new fabric.Rect({
        left: word.rect.x,
        top: word.rect.y,
        width: word.rect.width,
        height: word.rect.height,
        angle: word.rect.angle * 180 / Math.PI,
        originX: 'center',
        originY: 'center',
        stroke: 'red',
        strokeUniform: true,
        fill: 'transparent',
        selectionBackgroundColor: 'rgba(127,127,255,0.1)'
      })

      word.__rect.on('moved', () => this.onWordOverlayChanged([word]))
      word.__rect.on('scaled', () => this.onWordOverlayChanged([word]))
      word.__rect.on('rotated', () => this.onWordOverlayChanged([word]))

      this.canvas.add(word.__rect)
    }
  }

  private resizeCanvas () {
    if (!this.image) { return }

    this.scaleRatio = (this.canvasContainer.clientWidth - 2) / this.image.width

    if (this.scaleRatio === 0) { return }

    console.log('scaleRatio', this.scaleRatio)

    this.canvas.setDimensions({
      width: this.image.width * this.scaleRatio,
      height: this.image.height * this.scaleRatio
    })

    this.canvas.setZoom(this.scaleRatio)
  }

  // Hopefully not needed if future
  private correctBadBoundaries () {
    this.canvas.renderAll()

    const ctx = this.canvas.getContext()
    const imageData = ctx.getImageData(0, 0, this.canvasElement.width, this.canvasElement.height)
    const w = this.canvasElement.width

    for (const word of this.writingPiece!.words) {
      if (!/^[a-z0-9]+$/i.test(word.written_text)) { continue }

      let shouldNudge
      let ox = 0

      do {
        const c = word.__rect!.aCoords!

        const { x: sx, y: sy } = c.tl
        const { x: ex, y: ey } = c.bl

        shouldNudge = !pixelOnLine((sx | 0) + ox, sy | 0, (ex | 0) + ox, ey | 0, (x, y) => {
          const p = imageData.data[(x + y * w) * 4]

          if (p < 190) {
            // console.log(word.text)
            return false
          }

          return true
        })

        ox -= 1
      } while (shouldNudge && ox > -30)

      if (ox === 0) { continue }

      const rect = word.rect

      rect.x += ox * 0.5
      rect.width += (-ox)

      // const rectShape = new fabric.Rect({
      //   left: rect.x,
      //   top: rect.y,
      //   width: rect.width,
      //   height: rect.height,
      //   angle: rect.angle * 180 / Math.PI,
      //   originX: 'center',
      //   originY: 'center',
      //   stroke: 'blue',
      //   fill: 'transparent'
      // })

      // this.canvas.add(rectShape)

      word.__rect!.set('left', rect.x)
      word.__rect!.set('width', rect.width)

      this.onWordOverlayChanged([word])
    }

    this.canvas.requestRenderAll()
  }
}
